import Vue from "vue";
import VueRouter from "vue-router";
import store from "./../store/index";

Vue.use(VueRouter);
console.log(store)
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

const VueRouterPush =VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
    {
      path:'/',
      redirect:'/login'
    },
    // 登录login
    {
      path:'/login',
      name:'login',
      component:()=>import("@/views/login/login.vue"),
    },
    // 学生端
    {
      path:'/student',
      name:'student',
      component:()=>import("@/views/student/student.vue"),
      redirect: {
        name:'Projectapplication'
      },
      children:[
        // 项目提交
        {
          path:'/Projectapplication',
          name:'Projectapplication',
          meta:['项目管理','项目提交'],
          component:()=>import("@/views/student/Projectapplication.vue"),
          redirect: {
            name:'Projectinfor'
          },
          children:[
            {
              path:'/Projectinfor',
              name:'Projectinfor',
              meta:['项目管理','项目提交'],
              component:resolve=>require(['@/views/student/Projectinfor.vue'],resolve),
            },
            {
              path:'/Newmembers',
              name:'Newmembers',
              meta:['项目管理','项目提交'],
              component:resolve=>require(['@/views/student/Newmembers.vue'],resolve),
            },
            {
              path:'/Newteacher',
              name:'Newteacher',
              meta:['项目管理','项目提交'],
              component:resolve=>require(['@/views/student/Newteacher.vue'],resolve),
            },
            
          ]
        },
        // 项目查看
        {
          path:'/Projectview',
          name:'Projectview',
          meta:['项目管理','项目查看'],
          component:resolve=>require(['@/views/student/Projectview.vue'],resolve),
        },
        // 项目日志
        {
          path:'/ProjectSchedule',
          name:'ProjectSchedule',
          meta:['项目管理','项目日志'],
          component:resolve=>require(['@/views/student/ProjectSchedule.vue'],resolve),
        },
        // 项目专利
        {
          path:'/Projectpatent',
          name:'Projectpatent',
          meta:['项目管理','项目专利'],
          component:resolve=>require(['@/views/student/Projectpatent.vue'],resolve),
        },
        // 经费支持
        {
          path:'/Fundingsupport',
          name:'Fundingsupport',
          meta:['项目管理','经费上报'],
          component:resolve=>require(['@/views/student/Fundingsupport.vue'],resolve),
        },
        // 修改密码
        {
          path:'/Studentpassword',
          name:'Studentpassword',
          meta:['修改密码'],
          component:resolve=>require(['@/views/student/Studentpassword.vue'],resolve),
        },
        //结项报告
        {
          path:'/Postprojectreport',
          name:'Postprojectreport',
          meta:['项目管理','结项报告'],
          component:resolve=>require(['@/views/student/Postprojectreport.vue'],resolve),
          redirect: {
            name:'BasicProject'
          },
          children:[
            {
              path:'/BasicProject',
              name:'BasicProject',
              meta:['项目管理','结项报告','项目基础信息'],
              component:resolve=>require(['@/views/student/BasicProject.vue'],resolve),
            },
            {
              path:'/SummaryReport',
              name:'SummaryReport',
              meta:['项目管理','结项报告','项目总结报告'],
              component:resolve=>require(['@/views/student/SummaryReport.vue'],resolve),
            },
            {
              path:'/Useoffunds',
              name:'Useoffunds',
              meta:['项目管理','结项报告','经费使用情况'],
              component:resolve=>require(['@/views/student/Useoffunds.vue'],resolve),
            },
            {
              path:'/Viewclosingreport',
              name:'Viewclosingreport',
              meta:['项目管理','结项报告'],
              component:resolve=>require(['@/views/student/Viewclosingreport.vue'],resolve),
            },
          ]
        },
        
      ]
    },
    //教师端
    {
      path:'/teacher',
      name:'teacher',
      component:()=>import("@/views/teacher/teacher.vue"),
      redirect: {
        name:'ListofProjects'
      },
      children:[
        // 项目列表
        {
          path:'/ListofProjects',
          name:'ListofProjects',
          meta:['项目列表'],
          component:resolve=>require(['@/views/teacher/ListofProjects.vue'],resolve),
        },
        // 项目列表
        {
          path:'/Teachersviewdetails',
          name:'Teachersviewdetails',
          meta:['项目列表','项目详情'],
          component:resolve=>require(['@/views/teacher/Teachersviewdetails.vue'],resolve),
        },
        // 中期检查
        {
          path:'/Teachspection',
          name:'Teachspection',
          meta:['中期检查'],
          component:resolve=>require(['@/views/teacher/Teachspection.vue'],resolve),
        },
        // 经费审核
        {
          path:'/FundingTeachers',
          name:'FundingTeachers',
          meta:['经费审核'],
          component:resolve=>require(['@/views/teacher/FundingTeachers.vue'],resolve),
        },
        // 结项报告
        {
          path:'/Teacherreport',
          name:'Teacherreport',
          meta:['结项报告'],
          component:resolve=>require(['@/views/teacher/Teacherreport.vue'],resolve),
        },
        // 结项报告
        {
          path:'/Viewteacherreport',
          name:'Viewteacherreport',
          meta:['结项报告','项目详情'],
          component:resolve=>require(['@/views/teacher/Viewteacherreport.vue'],resolve),
        },
        // 修改密码
        {
          path:'/teacherPassword',
          name:'teacherPassword',
          meta:['修改密码'],
          component:resolve=>require(['@/views/teacher/teacherPassword.vue'],resolve),
        },
      ],
    },
    // 超级管理员端
    {
      path:'/admin',
      name:'admin',
      component:()=>import("@/views/admin/admin.vue"),
      redirect: {name:'Userment'},
      children:[
        //用户管理
        {
          path:'/Userment',
          name:'Userment',
          meta:['用户管理'],
          component:resolve=>require(['@/views/admin/Userment.vue'],resolve),
        },
        //教师管理
        {
          path:'/FieldTeacher',
          name:'FieldTeacher',
          meta:['教师管理'],
          component:resolve=>require(['@/views/admin/FieldTeacher.vue'],resolve),
        },
        
        // 批次控制
        {
          path:'/Batchcontrol',
          name:'Batchcontrol',
          meta:['项目管理','批次控制'],
          component:resolve=>require(['@/views/admin/Batchcontrol.vue'],resolve),
        },
        
        // 项目审核
        {
          path:'/Projectbatch',
          name:'Projectbatch',
          meta:['项目管理','项目审核'],
          component:resolve=>require(['@/views/admin/Projectbatch.vue'],resolve),
        },
        // 项目列表
        {
          path:'/Alistofitems',
          name:'Alistofitems',
          meta:['项目管理','项目列表'],
          component:resolve=>require(['@/views/admin/Alistofitems.vue'],resolve),
        },
        //项目详情页
        {
          path:'/Projectdetails',
          name:'Projectdetails',
          meta:['项目管理','项目详情'],
          component:resolve=>require(['@/views/admin/Projectdetails.vue'],resolve),
        },
        // 项目申报
        {
          path:'/Projectcation',
          name:'Projectcation',
          meta:['项目管理','项目申报'],
          component:resolve=>require(['@/views/admin/Projectcation.vue'],resolve),
        },
        // 项目检查
        {
          path:'/Projectcheck',
          name:'Projectcheck',
          meta:['项目管理','中期检查'],
          component:resolve=>require(['@/views/admin/Projectcheck.vue'],resolve),
        },
        // 结项报告
        {
          path:'/Postreport',
          name:'Postreport',
          meta:['项目管理','结项报告'],
          component:resolve=>require(['@/views/admin/Postreport.vue'],resolve),
        },
        // 资金管理
        {
          path:'/Matchingfunds',
          name:'Matchingfunds',
          meta:['资金管理'],
          component:resolve=>require(['@/views/admin/Matchingfunds.vue'],resolve),
        },
        
        // 专利统计
        {
          path:'/Patentstatistical',
          name:'Patentstatistical',
          meta:['数据统计','专利统计'],
          component:resolve=>require(['@/views/admin/Patentstatistical.vue'],resolve),
        },
        // 项目统计
        {
          path:'/Projectstatistics',
          name:'Projectstatistics',
          meta:['数据统计','项目统计'],
          component:resolve=>require(['@/views/admin/Projectstatistics.vue'],resolve),
        },
        // 资金统计
        {
          path:'/Moneystatistics',
          name:'Moneystatistics',
          meta:['数据统计','资金统计'],
          component:resolve=>require(['@/views/admin/Moneystatistics.vue'],resolve),
        },
        // 修改密码
        {
          path:'/AdminPassword',
          name:'AdminPassword',
          meta:['修改密码'],
          component:resolve=>require(['@/views/admin/AdminPassword.vue'],resolve),
        },
      ]
    },
    //院级管理员
    {
      path:'/Seconadmin',
      name:'Seconadmin',
      component:()=>import("@/views/Seconadmin/Seconadmin.vue"),
      redirect: {name:'Teacherment'},
      children:[
        //教师管理
        {
          path:'/Teacherment',
          name:'Teacherment',
          meta:['教师管理'],
          component:resolve=>require(['@/views/Seconadmin/Teacherment.vue'],resolve),
        },
        // 项目批次
        {
          path:'/Seconbatch',
          name:'Seconbatch',
          meta:['项目管理','项目批次'],
          component:resolve=>require(['@/views/Seconadmin/Seconbatch.vue'],resolve),
        },
        // 项目列表
        {
          path:'/Seconlistofitems',
          name:'Seconlistofitems',
          meta:['项目管理','项目列表'],
          component:resolve=>require(['@/views/Seconadmin/Seconlistofitems.vue'],resolve),
        },
        //项目详情页
        {
          path:'/Secondetails',
          name:'Secondetails',
          meta:['项目管理','项目详情'],
          component:resolve=>require(['@/views/Seconadmin/Secondetails.vue'],resolve),
        },
        //项目申报
        {
          path:'/Seconectcation',
          name:'Seconectcation',
          meta:['项目管理','项目申报'],
          component:resolve=>require(['@/views/Seconadmin/Seconectcation.vue'],resolve),
        },
        //中期检查
        {
          path:'/Secontcheck',
          name:'Secontcheck',
          meta:['项目管理','中期检查'],
          component:resolve=>require(['@/views/Seconadmin/Secontcheck.vue'],resolve),
        },
        //结项报告
        {
          path:'/SeconPostreport',
          name:'SeconPostreport',
          meta:['项目管理','结项报告'],
          component:resolve=>require(['@/views/Seconadmin/SeconPostreport.vue'],resolve),
        },
        //资金管理
        {
          path:'/Seconingfunds',
          name:'Seconingfunds',
          meta:['项目管理','资金管理'],
          component:resolve=>require(['@/views/Seconadmin/Seconingfunds.vue'],resolve),
        },
        // 修改密码
        {
          path:'/SeconPassword',
          name:'SeconPassword',
          meta:['修改密码'],
          component:resolve=>require(['@/views/Seconadmin/SeconPassword.vue'],resolve),
        },
      ]
    },
    // 评审端
    {
      path:'/review',
      name:'review',
      component:()=>import("@/views/review/review.vue"),
      redirect: {name:'Notreview'},
      children:[
        //项目评审
        {
          path:'/Notreview',
          name:'Notreview',
          meta:['项目评审'],
          component:resolve=>require(['@/views/review/Notreview.vue'],resolve),
        },
        //项目列表
        {
          path:'/Hasassessed',
          name:'Hasassessed',
          meta:['项目列表'],
          component:resolve=>require(['@/views/review/Hasassessed.vue'],resolve),
        },
        //项目详情
        {
          path:'/Reviewdetails',
          name:'Reviewdetails',
          meta:['项目详情'],
          component:resolve=>require(['@/views/review/Reviewdetails.vue'],resolve),
        },
        // 修改密码
        {
          path:'/reviewPassword',
          name:'reviewPassword',
          meta:['修改密码'],
          component:resolve=>require(['@/views/review/reviewPassword.vue'],resolve),
        },
      ]
    }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
    let token = localStorage.getItem('token');
    if (token === null || token === '') {
      next('/login');
    } else {
      next();
    }
  }
});
export default router;
