import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token:localStorage.getItem('token') ? localStorage.getItem('token') : '',   //token标识
    uid:localStorage.getItem('uid') ? localStorage.getItem('uid') : '',         //用户登录id
    itemId:localStorage.getItem('itemId') ? JSON.parse(localStorage.getItem('itemId')) : null,         //项目Id
    schId:localStorage.getItem('schId') ? localStorage.getItem('schId') : '',         //学校Id
    uploadimage:localStorage.getItem('uploadimage') ? localStorage.getItem('uploadimage') : '',         //图片地址
    teaId:localStorage.getItem('teaId') ? localStorage.getItem('teaId') : '',                     //教师Id
    academyId:localStorage.getItem('academyId') ? localStorage.getItem('academyId') : '',                     //院系Id
    schName:localStorage.getItem('schName') ? localStorage.getItem('schName') : '',                     //学校名称
    depName:localStorage.getItem('depName') ? localStorage.getItem('depName') : '',                     //院系名称
    startTime:localStorage.getItem('startTime') ? localStorage.getItem('startTime') : '',                     //项目开始时间
    endTime:localStorage.getItem('endTime') ? localStorage.getItem('endTime') : '',                     //项目结束时间
  },
  mutations: {
    token(state,value){
      state.token = value
      localStorage.setItem('token',value);
    },
    uid(state,value){
      state.uid = value
      localStorage.setItem('uid',value);
    },
    itemId(state,value){
      state.itemId = value
      localStorage.setItem('itemId',value);
    },
    schId(state,value){
      state.schId = value
      localStorage.setItem('schId',value);
    },
    uploadimage(state,value){
      state.uploadimage = value
      localStorage.setItem('uploadimage',value);
    },
    teaId(state,value){
      state.teaId = value
      localStorage.setItem('teaId',value);
    },
    academyId(state,value){
      state.academyId = value
      localStorage.setItem('academyId',value);
    },
    schName(state,value){
      state.schName = value
      localStorage.setItem('schName',value);
    },
    depName(state,value){
      state.depName = value
      localStorage.setItem('depName',value);
    },
    startTime(state,value){
      state.startTime = value
      localStorage.setItem('startTime',value);
    },
    endTime(state,value){
      state.endTime = value
      localStorage.setItem('endTime',value);
    },
  },
  actions: {
    token(context,value){
      context.commit('token',value)
    },
    uid(context,value){
      context.commit('uid',value)
    },
    itemId(context,value){
      context.commit('itemId',value)
    },
    schId(context,value){
      context.commit('schId',value)
    },
    uploadimage(context,value){
      context.commit('uploadimage',value)
    },
    teaId(context,value){
      context.commit('teaId',value)
    },
    academyId(context,value){
      context.commit('academyId',value)
    },
    schName(context,value){
      context.commit('schName',value)
    },
    depName(context,value){
      context.commit('depName',value)
    },
    startTime(context,value){
      context.commit('startTime',value)
    },
    endTime(context,value){
      context.commit('endTime',value)
    },
    
  },
  getters: {
    
  },
});
