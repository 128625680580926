import axios from 'axios'
import {get, post, put} from '../utils/request'
axios.prototype.get = get
axios.prototype.post = post
axios.prototype.put = put

export default {
    // 登录
    adminlogin(data) {
        return axios.post('admin/login',data)
    },
    //登录验证码发送接口
    duanxin(data) {
        return axios.post('admin/duanxin',data)
    },
    //注册
    adminregist(data) {
        return axios.post('admin/add',data)
    },
    //注册验证发送接口
    adminzhuce(data) {
        return axios.post('admin/zhuce',data)
    },
    commonupload(data) {
        return axios.post('common/upload',data)
    },
    // 学生端项目查看
    getInfo(data) {
        return axios.get( 'studentItem/uid',data)
    },
    // 教师端项目列表查询
    teacheritemlist(data) {
        return axios.get( 'studentItem/teacheritemlist',data)
    },
    // 教师端根据项目id查询项目详情
    studentItemitemId(data) {
        return axios.get( 'studentItem/itemId',data)
    },
    //  新增【项目信息】
    addStudent(data) {
        return axios.post( 'studentItem/add',data)
    },
    //  修改【项目信息】
    uptItemStudent(data) {
        return axios.put( 'studentItem/uptItem',data)
    },
    //  新增【项目成员】
    addDcStudent(data) {
        return axios.post( 'student/add',data)
    },
    //  学校端根据用户id查询所在学校级院系列表接口
    studentuid(data) {
        return axios.get( 'student/uid',data)
    },
    //  学生端根据用户id查询所在学校级院系列表接口
    studentuid2(data) {
        return axios.get( 'student/uid2',data)
    },
    //  新增【指导教师】
    addDcItemTeacher(data) {
        return axios.post( 'itemteacher/add',data)
    },
    //  删除【指导老师】
    deleteTeacher(data) {
        return axios.delete( 'itemteacher/itemTid',data)
    },
    //  删除【删除团队成员】
    deleteStudent(data) {
        return axios.delete( 'student/sId',data)
    },
    //  查询【根据学校id老师】列表
    listDcTeacher(data) {
        return axios.get( 'teacher/list',data)
    },
    //根据项目id查询【专利】列表
    patentsList(data) {
        return axios.get( 'patents/list',data)
    },
    //新增【专利】
    patentsAdd(data) {
        return axios.post( 'patents/add',data)
    },
    //删除【专利】
    patentsDelete(data) {
        return axios.delete( 'patents/delete',data)
    },
    //查询【根据项目id经费】列表
    expenditureList(data) {
        return axios.get( 'expenditure/list',data)
    },
    // 新增【经费】
    addDcItemExpenditure(data) {
        return axios.post( 'expenditure/add',data)
    },
    // 删除经费
    expenditureDelete(data) {
        return axios.delete( 'expenditure/delete',data)
    },
    //  指导教师推荐意见
    studentItemupdate(data) {
        return axios.put( 'studentItem/update',data)
    },
    updateYj(data) {
        return axios.put( 'studentItem/updateYj',data)
    },
    //  根据教师iD查询经费列表接口
    expenditureexpendlist(data) {
        return axios.get( 'expenditure/expendlist',data)
    },
    //  经费通过/驳回接口
    expenditureupdate(data) {
        return axios.put( 'expenditure/update',data)
    },
    //  查询校级所有项目列表口/驳回接口
    studentItemlist(data) {
        return axios.get( 'studentItem/list',data)
    },
    //提交评审
    uptItemState(data) {
        return axios.put( 'studentItem/uptItemState',data)
    },
    //查询校级所有已申报项目
    itemlist(data) {
        return axios.get( 'studentItem/itemlist',data)
    },
    //查询学校的经费列表接口
    management(data) {
        return axios.get( 'studentItem/management',data)
    },
    //教师端根据项目id查询经费详情信息接口
    studenttexpen(data) {
        return axios.get( 'studentItem/texpen',data)
    },
    //查询日志
    loglist(data) {
        return axios.get( 'log/list',data)
    },
    //添加日志
    logadd(data) {
        return axios.post( 'log/add',data)
    },
    //删除日志
    logdelete(data) {
        return axios.delete( 'log/delete',data)
    },
    //查询校级管理员
    adminlist(data) {
        return axios.get( 'admin/list',data)
    },
    //添加院系管理员
    adminbuild(data) {
        return axios.post( 'admin/build',data)
    },
    //添加院系管理员
    admindelete(data) {
        return axios.delete( 'admin/delete',data)
    },
    //查询知道教师
    teacherbysid(data) {
        return axios.get( 'teacher/bysid',data)
    },
    //查询知道教师
    teacherbyaid(data) {
        return axios.get( 'teacher/byaid',data)
    },
    //添加指导教师
    teacherradd(data) {
        return axios.post( 'teacher/add',data)
    },
    //修改指导教师
    teacherredit(data) {
        return axios.put( 'teacher/edit',data)
    },
    //删除指导教师
    teacherdelete(data) {
        return axios.delete( 'teacher/delete',data)
    },
    //根据院级管理员id查询项目列表列表口
    yitemlist(data) {
        return axios.get( 'studentItem/yitemlist',data)
    },
    //院管理员端根据项目id查询待审核经费列表接口
    yexpen(data) {
        return axios.get( 'studentItem/yexpen',data)
    },
    //院管理员端根据项目id查询待审核经费列表接口
    yexpendlist(data) {
        return axios.get( 'expenditure/yexpendlist',data)
    },
    //院管理员端根据项目id查询待审核经费列表接口
    wverify(data) {
        return axios.get( 'studentItem/wverify',data)
    },
    //获取评委接口
    judge(data) {
        return axios.get( 'review/judge',data)
    },
    //提交评审
    reviewrefer(data) {
        return axios.post( 'review/refer',data)
    },
    //查询评委待评审项目列表
    reviewwlist(data) {
        return axios.get( 'review/wlist',data)
    },
    //评分
    reviewsubmit(data) {
        return axios.post( 'review/submit',data)
    },
    //查询评委已评审项目列表
    reviewrlist(data) {
        return axios.get( 'review/rlist',data)
    },
    //查询评委已评审项目列表
    declare(data) {
        return axios.get( 'studentItem/declare',data)
    },
    //查询评委已评审项目列表
    exportDeclare(data) {
        return axios.get( 'studentItem/exportDeclare',data)
    },
    //获取用户信息接口
    gadminetInfo(data) {
        return axios.get( 'admin/getInfo',data)
    },
    // 修改学生信息接口
    adminedit(data) {
        return axios.put( 'admin/edit',data)
    },
    // 修改学生信息接口
    statisDeclare(data) {
        return axios.get( 'studentItem/statisDeclare',data)
    },
    // 导出项目
    exportItem(data) {
        return axios.get( 'studentItem/exportItem',data)
    },
    // 根据学校id查询项目批次
    batch(data) {
        return axios.get( 'itemteacher/batch',data)
    },
    // 新增项目批次
    batchAdd(data) {
        return axios.post( 'itemteacher/batchAdd',data)
    },
    // 修改项目批次
    batchEdit(data) {
        return axios.put( 'itemteacher/batchEdit',data)
    },
    // 修改项目批次
    exportItems(data) {
        return axios.get( 'studentItem/exportItems',data)
    },
}